.faq {
    &__inner {
        padding-top: calc(70px + (130 - 70) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));
    }
    &__text-detail {
        margin-bottom: 10px;
    }
    &__title {
        margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1050px) {
            flex-direction: column;
        }
    }
    &__list {
        flex: 0 1 600px;
        @media (max-width: 1050px) {
            flex: 0 1 auto;
        }
        &--left {
            margin-right: 20px;
            @media (max-width: 1050px) {
                margin-right: 0;
            }
        }
    }
    &__item  {
        border-bottom: 1px solid #815F86;
        &.active {
            & .faq__icon-down {
                display: none;
            }
        }
        &.active {
            & .faq__icon-up {
                display: block;
                transform: scale(1);
            }
        }
    }
    &__question {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-top: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-violet;
    }
    &__question-text {
        font-weight: 600;
        margin-right: auto;
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width: 479px) {
            max-width: calc(240px + (420 - 240) * ((100vw - 320px) / (479 - 320)));
        }
    }
    &__icon-down {

    }
    &__icon-up {
        display: none;
    }
    &__answer {
        display: none;
        padding-bottom: calc(17px + (40 - 17) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(19px + (27 - 19) * ((100vw - 320px) / (1920 - 320)));
    }
}