/* colors */
$txt-main: #815F86;
$txt-white: #F5F5F5;
$txt-orange: #FE9C7F;
$txt-violet: #280F36;
$txt-violet2: #632B6C;
$btn-txt: #F5F5F5;
$bg-violet: #632B6C;

/* @font-face {
  font-family: 'Bebas Neue Pro';
  font-display: swap;
  src: url('../fonts/BebasNeuePro-ExpandedExtraBold-800.woff2') format('woff2'),
      url('../fonts/BebasNeuePro-ExpandedExtraBold-800.woff') format('woff');
  font-weight: 800;
  font-style: normal;
} */


/* Стандартный transition для hover-анимаций */

@mixin transition {
  transition: 0.3s cubic-bezier(0.250, 0.100, 0.250, 1.000);
}

/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
@mixin placeholder($color, $opacity: 1) {
  &::-webkit-input-placeholder {color:$color; opacity: $opacity}
  &::-moz-placeholder          {color:$color; opacity: $opacity}
  &:-ms-input-placeholder      {color:$color; opacity: $opacity}
}

//flex-миксин
@mixin flex-dir($dir: column, $jc:center, $ai:center) {
  display: flex;
  flex-direction: $dir;
  justify-content: $jc;
  align-items: $ai;
}

//Перевод пикселей в емы
@function rem($pixels, $context: 16) {
  @return $pixels / $context * 1rem;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: $txt-main;
}

a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

.fade-animation {
  -webkit-animation: fade-animation;
  animation: fade-animation 2s linear;
  //will-change: animation;
}

@-webkit-keyframes fade-animation {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.active {
  display: block;
}

/* container */
.container {
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 20px;
}

.text-detail {
  font-weight: 600;
  font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));
  text-transform: uppercase;
  color: $txt-orange;
  
}

.title {
  font-family: "Ubuntu", sans-serif;
  font-size: calc(24px + (52 - 24) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(31px + (68 - 31) * ((100vw - 320px) / (1920 - 320)));
  color: $txt-violet;
  
}

@import './src/blocks/btn/btn';
@import './src/blocks/separator/separator';
@import './src/blocks/price/price';
@import './src/blocks/timer/timer';
@import './src/blocks/form/form';

@import './src/blocks/header/header';
@import './src/blocks/main/main';
@import './src/blocks/change/change';
@import './src/blocks/offer/offer';
@import './src/blocks/program/program';
@import './src/blocks/get/get';
@import './src/blocks/know/know';
@import './src/blocks/order/order';
@import './src/blocks/life/life';
@import './src/blocks/faq/faq';
@import './src/blocks/reviews/reviews';
@import './src/blocks/footer/footer';


