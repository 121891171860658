$black: #000000;
$light-grey: #ADADAD;
$blue: #3485FF;
$white: #ffffff;
$light: #CBCBCB;
$dark-grey: #535353;
$red: #FF001F;
$green: #00B557;

@mixin respond($device) {

  @if $device == lg {
    @media only screen and (max-width: 1199px) {
      @content;
    }
  };

  @if $device == md {
    @media only screen and (max-width: 1023px) {
      @content;
    }
  };

  @if $device == sm {
    @media only screen and (max-width: 767px) {
      @content;
    }
  };

  @if $device == xs {
    @media only screen and (max-width: 567px) {
      @content;
    }
  };
}


.form {

  &__mail {
    display: none;
    padding: 16px 21px 29px 16px;
    background-color: $white;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(165, 165, 165, 0.21);
    border-radius: 4px;
    margin-bottom: 20px;
    @include respond(xs) {
      padding: 16px 24px 24px;
    }
  }
  &__title {
    font-family: 'Gotham', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: $black;
    margin-bottom: 8px;

    @include respond(md) {
      font-size: 16px;
    }
    @include respond(sm) {
      font-size: 12px;
    }
  }

  &__input-container {
    position: relative;
  }
  &__input {
    background: #E5E5E5;
    border: 1px solid $light;
    border-radius: 4px;
    width: 100%;
    padding: 8px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: $light;
    text-indent: 11px;
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: $light;
    }
  }
}
.basket {
  &__content {
    width: 100%;
    @include respond(xs) {
      max-width: 100%;
    }
  }
  &__button {
    width: 100%;
    border: none;
    outline: none;
    transition: all 0.4s ease-in-out;
    position: relative;
    z-index: 10;
    &:hover {
      transform: scale(1.05);
      background: linear-gradient(135deg, #C86B98 0%, #C6426E 100%);
    }
    &:disabled {
      cursor: not-allowed;
      background: grey;
    }
  }
  &__check, &__check2 {
    position: relative;
    //display: flex;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
    z-index: 10;
    /* margin-bottom: 18px; */
    display: none;
  }
  &__check-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__check-box {
    user-select: none;
  }
  &__check-input + label::before {
    content: '';
    display: inline-block;
    margin-right: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #535353;
    border-radius: 4px;
    cursor: pointer;
  }
  &__check-input:checked+label::before {
    background-image: url("../img/checkPay.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
  }
  &__check-text {
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    color: rgba(245, 245, 245, 0.6);
    &--data {
      text-decoration: underline;
    }
    &--top {
      font-size: 10px;
    }
    &--data-top {
      text-decoration: underline;
      font-size: 12px;
      color: blue;
    }
  }
}
.new-price,
.old-price {
  white-space: nowrap;
}
.old-price {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $dark-grey;
  position: relative;
  &:before {
    content: '';
    width: 119%;
    height: 1px;
    background-color: $dark-grey;
    position: absolute;
    transform: rotate(-15deg) translate(-8px, 9px);
  }
  @include respond(xs) {
    font-size: 12px;
    margin-bottom: 8px;
    &:before {
      transform: rotate(-15deg) translate(-5px, 7px);
    }
  }
}
.new-price {
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  color: #FF001F;
  &_sm {
    font-size: 14px;
  }
}
.footer {
  background-color: transparent;
  padding: 40px 0 21px;
  display: flex;
  align-items: flex-end;
  flex-grow: 4;
  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 270px;
  }
  &__info {
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 114%;
    color: #000000;
    margin-bottom: 8px;
    &--link {
      text-decoration: none;
    }
    &--grey {
      color: #595959;
    }
    &--legal {
      margin-bottom: 0;
      line-height: 170%;
    }
  }
  &__pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 14px;
    width: 100%;
    max-width: 280px;
  }
}
@media (max-width: 800px) {
  .footer {
    &__inner {
      flex-wrap: wrap;
    }
    &__item{
      &--middle {
        order: 2;
      }
    }
    &__info {
      &--mb {
        color: #595959;
      }
    }
  }
}
@media (max-width: 740px) {
  .footer {
  padding: 16px 0 0;}

  .main-link {
    padding: 0 16px;
    margin: 16px 0;
  }
}