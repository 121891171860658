.change {
    position: relative;
    &__inner {
        padding-top: 80px;
        padding-bottom: 65px;
        @media (max-width: 479px) {
            padding-top: 70px;
            padding-bottom: 40px;
        }
    }
    &__text-detail {
        margin-bottom: 10px;
    }
    &__title {
        margin-bottom: 60px;
        @media (max-width: 479px) {
            margin-bottom: 30px;
        }
    }
    &__list {
        display: grid;
        grid-template: auto / repeat(auto-fill,minmax(280px, 1fr));
        gap: 75px;
        @media (max-width: 479px) {
            gap: 20px;
        }
    }
    &__item {

    }
    &__item-title {
        font-weight: 600;
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-violet;
        padding-left: 35px;
        position: relative;
        margin-bottom: 20px;
        @media (max-width: 479px) {
            margin-bottom: 10px;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background: url(../img/change-star.png) no-repeat;
        }
    }
    &__item-descr {
        padding-left: 35px;
    }
    &__bg {
        position: absolute;
        top: -100px;
        right: 0;
        @media (max-width: 767px) {
            display: none;
        }
    }
}