.price__new {
    display: inline-block;
    font-weight: bold;
    font-size: 42px;
    line-height: 63px;
    color: #FE9C7F;
    @media (max-width: 1126px) {
        font-size: 30px;
        line-height: 45px;
    }
    span {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
    
}
.price__text {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
}
.price__old {
    display: inline-block;
    text-decoration: line-through;
}