.program {
    background: #f9edf2;
    position: relative;
    &__inner {
        padding-top: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(65px + (130 - 65) * ((100vw - 320px) / (1920 - 320)));
    }
    &__text-detail {
        text-align: center;
        margin-bottom: 10px;
    }
    &__title {
        text-align: center;
        margin-bottom: calc(30px + (80 - 30) * ((100vw - 320px) / (1920 - 320)));
    }
    &__list {
        display: grid;
        justify-items: center;
        grid-template: auto / repeat(auto-fill,minmax(280px, 1fr));
        gap: 75px;
        margin-bottom: 60px;
        @media (max-width: 674px) {
            gap: 30px;
        }
    }
    &__item {
    }
    &__img {
        margin-bottom: 20px;
        @media (max-width: 360px) {

        }
        img {
            width: 100%;
            border-radius: 40px 0px;
            @media (max-width: 360px) {
                border-radius: 30px 0px;
            }
        }
    }
    &__text {
        background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 10px;
    }
    &__item-title {
        font-weight: 600;
        font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(25px + (36 - 25) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-violet;
        margin-bottom: 10px;
    }
    &__descr {
        max-width: 380px;
        font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(19px + (27 - 19) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width: 674px) {
            max-width: 280px;
        }
    }
    &__btn {
        position: relative;
        border: none;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(280px + (410 - 280) * ((100vw - 320px) / (1920 - 320)));
        height: calc(50px + (59 - 50) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(20px + (27 - 20) * ((100vw - 320px) / (1920 - 320)));
        text-align: center;
        color: $txt-violet;
        background: 
            linear-gradient(#F9EDF2,#F9EDF2) padding-box,
            linear-gradient(135deg, #642B73 0%, #C6426E 100%) border-box;
        border-radius: 10px;
        border: 3px solid transparent;
        transition: all 0.3s;
        &:hover {
            background: linear-gradient(135deg, #642B73 0%, #C6426E 100%) border-box;
            color: $txt-white;
        }
    }
}

.separator__program-top {
    top: calc(-35px + (-90 - -35) * ((100vw - 320px) / (1920 - 320)));
    path {
        fill: #F9EDF2;
    }
}
.separator__program-bottom {
    bottom: calc(-35px + (-90 - -35) * ((100vw - 320px) / (1920 - 320)));
    path {
        fill: #F9EDF2;
    }
}
