.get {
    position: relative;
    overflow: hidden;
    &__inner {
        max-width: 1520px;
        padding-top: calc(85px + (215 - 85) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));
    }
    &__wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url(../img/get-bg.jpg) no-repeat;
        background-size: cover;
        border-radius: 10px;
        padding: 100px 115px;
        padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
        padding-right: calc(20px + (115 - 20) * ((100vw - 320px) / (1920 - 320)));
        padding-left: calc(20px + (115 - 20) * ((100vw - 320px) / (1920 - 320)));
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 220px;
            height: 173px;
            top: -80px;
            right: -80px;
            background: url(../img/get-stars.png) no-repeat;
        }
        @media (max-width: 767px) {
            flex-wrap: wrap;
            text-align: center;
        }
    }
    &__block-text {
        max-width: 650px;
        margin-right: 15px;
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }
    &__block-title {
        font-weight: 600;
        font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(25px + (36 - 25) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-white;
        margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    &__block-subtitle {
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-white;
        @media (max-width: 767px) {
            margin-bottom: 40px;
        }
    }
    &__form {
        width: 100%;
        max-width: 520px;
        @media (max-width: 767px) {
            margin: 0 auto;
        }
    }
    &__form-title {
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-white;
    }
    &__form-btn {
        background: linear-gradient(135deg, #C86B98 0%, #C6426E 100%);
        transition: background 0.3s;
        &:hover {
            background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
        }
    }
}