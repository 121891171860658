.main {
    background: url(../img/main-bg-desk.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    @media (max-width: 479px) {
        background: url(../img/main-bg-mobile.jpg) no-repeat;
        background-position: bottom center;
        background-size: cover;
    }
    &__inner {
        padding-top: 210px;
        padding-bottom: 270px;
        @media (max-width: 767px) {
            padding-top: 165px;
            padding-bottom: 165px;
        }
        @media (max-width: 320px) {
            padding-top: 225px;
            padding-bottom: 90px;
        }
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            justify-content: center;
        }
    }
    &__block-text {
        max-width: 500px;
        @media (max-width: 767px) {
            
        }
    }
    &__title {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: calc(40px + (86 - 40) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(48px + (103 - 48) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-white;
        margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width: 767px) {
            text-align: center;
            margin-bottom: 20px;
        }
        @media (max-width: 360px) {
            max-width: 250px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
    &__subtitle {
        font-size: 20px;
        line-height: 30px;
        color: $txt-white;
        @media (max-width: 949px) {
            margin-bottom: 80px;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }
    &__block-form {
        flex: 0 1 410px;
    }
    &__form-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: $txt-white;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            display: block;
            text-align: center;
        }
    }
    &__btn {
        max-width: 410px;
        width: 100%;
        @media (max-width: 767px) {
            margin: 0 auto;
        }
    }
}
.body__bg2 {
    position: absolute;
    z-index: 500;
    left: 0;
    top: 2450px;
    @media (max-width: 767px) {
        display: none;
    }
}
.body__bg3 {
    position: absolute;
    z-index: 500;
    left: 0;
    top: 5350px;
    @media (max-width: 767px) {
        display: none;
    }
}
.body__bg4 {
    position: absolute;
    z-index: 500;
    right: 0;
    top: 7750px;
    @media (max-width: 767px) {
        display: none;
    }
}