.reviews {
    position: relative;
    &__inner {
        padding-top: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));
    }
    &__list {
        display: grid;
        gap: calc(30px + (90 - 30) * ((100vw - 320px) / (1920 - 320)));
        grid-template-columns: repeat(2,1fr);
        @media (max-width: 767px) {
            grid-template-columns: 100%;
        }
    }
    &__item {
        &:not(:first-child) {
            display: flex;
            flex-direction: column;
            background: linear-gradient(135deg, rgba(200, 107, 152, 0.1) 0%, rgba(198, 66, 110, 0.1) 100%);
            border-radius: 20px;
            padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))) calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
    }
    &__text-detail {
        margin-bottom: 10px;
    }
    &__title {
        margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    }
    &__text {
        margin-bottom: auto;
    }
    &__item-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

    }
    &__item-title {
        font-weight: 600;
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-violet;
        @media (max-width: 767px) {
            order: 2;
        }
        
    }
    &__rating {
        width: 140px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
            order: 1;
            margin: 0 auto;
            margin-bottom: 13px;
        }
    }
    &__img {

    }
    &__date {
        font-size: 15px;
        line-height: 22px;
        color: $txt-violet;
        margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
}
.separator__program-bottom--color {
    path {
        fill: #fff;
    }
}