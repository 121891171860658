.header {
    position: fixed;
    background: rgba(40, 15, 54, 0.8);
    width: 100%;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 50;
    &__inner {
        display: flex;
        align-items: center;
        @media (max-width: 479px) {
            justify-content: flex-end;
        }
    }
    &__logo {
        max-width: 108px;
        margin-right: auto;
        img {
            width: 100%;
        }
    }
    &__enter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 43px;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: $btn-txt;
        background: #280F36;
        border-radius: 10px;
        transition: 0.3s background;
        &::before {
            content: "";
            position: absolute;
            top: -3px;
            right: -3px;
            bottom: -3px;
            left: -3px;
            background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
            border-radius: 12px;
            z-index: -1;
        }
        &:hover {
            background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);;
        }
    }
}