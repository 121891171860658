.know {
    overflow: hidden;
    &__inner {
        padding-top: calc(30px + (65 - 30) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(170px + (150 - 170) * ((100vw - 320px) / (1920 - 320)));
    }
    &__text-detail {
        margin-bottom: 10px;
    }
    &__title {
        margin-bottom: 60px;
        z-index: 10;
        position: relative;
        @media (max-width: 479px) {
            margin-bottom: 30px;
        }
    }
    &__descr {
        max-width: 630px;
        margin-bottom: calc(150px + (190 - 150) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));

    }
    &__list {
        display: flex;
        flex-direction: column;
    }
    &__item {
        position: relative;
        z-index: 5;
        &:nth-child(1) {
            margin-bottom: 180px;
            margin-left: auto;
            margin-right: 200px;
            @media (max-width: 667px) {
                margin: 0 auto 170px;
            }
            @media (max-width: 360px) {
                margin: 0 auto 130px;
            }
        }
        &:nth-child(2) {
            margin-bottom: 120px;
            margin-left: 200px;
            @media (max-width: 767px) {
                margin-bottom: 180px;
            }
            @media (max-width: 667px) {
                margin: 0 auto 170px;
            }
            @media (max-width: 360px) {
                margin: 0 auto 130px;
            }
        }
        &:nth-child(3) {
            margin-bottom: 180px;
            margin-left: auto;
            margin-right: 200px;
            @media (max-width: 667px) {
                margin: 0 auto 170px;
            }
            @media (max-width: 360px) {
                margin: 0 auto 130px;
            }
        }
        &:nth-child(4) {
            margin-left: 200px;
            @media (max-width: 667px) {
                margin: 0 auto;
            }
        }
    }
    &__item-wrapper {
        display: flex;
        max-width: 545px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 10;
        @media (max-width: 667px) {
            flex-direction: column;
        }
    }
    &__item-block {
        order: 1;
        background: #FFFFFF;
        width: 385px;
        height: 130px;
        padding: 10px 10px 20px;
        border-radius: 10px;
        @media (max-width: 667px) {
            width: 280px;
            order: 5;
            height: 100px;
            padding: 10px 20px;
        }
        &--1,
        &--3 {
            text-align: right;
            margin-right: 10px;
            @media (max-width: 667px) {
                text-align: left;
                margin-right: 0;
            }
        }
        &--2,
        &--4 {
            text-align: left;
        }
    }
    &__item-title {
        font-weight: 600;
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-violet;
        margin-bottom: 10px;
    }
    &__item-text {
        font-size: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    &__item-img {
        @media (max-width: 667px) {
            order: 1;
            margin-bottom: 10px;
            width: 100px;
            height: 100px;
        }
        &--1,
        &--3 {
            order: 2;
            border-radius: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))) 0px;
        }
        &--2,
        &--4 {
            border-radius: 0px calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
        img {

        }
    }
    &__item-bg {
        position: absolute;
        z-index: -1;
        width: calc(280px + (450 - 280) * ((100vw - 320px) / (1920 - 320)));
        height: calc(280px + (450 - 280) * ((100vw - 320px) / (1920 - 320)));
        bottom: -55px;
        &--1,
        &--3 {
            right: -200px;
            @media (max-width: 767px) {
                right: -100px;
            }
            @media (max-width: 667px) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 45px;
            }
        }
        &--2,
        &--4 {
            left: -200px;
            @media (max-width: 767px) {
                left: -100px;
            }
            @media (max-width: 667px) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 45px;
            }
        }
    }
}