.separator {
    height: calc(35px + (90 - 35) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;
    z-index: 4;
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    /* @media (max-width: 767px) {
      height: 40px;
    }
    @media (max-width: 479px) {
      height: 35px;
    } */
  }
  .separator-top {
    top: 0;
  }
  .separator-bottom {
    bottom: 0;
  }
  .separator svg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }