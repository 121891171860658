.order {
    max-width: 1520px;
    margin: 0 auto;
    background: url(../img/order-bg.jpg) no-repeat;
    background-size: cover;
    @media (max-width: 360px) {
        background: url(../img/order-bg-mobile.jpg) no-repeat;
        background-size: cover;
    }
    &__inner {
        position: relative;
    }
    &__form {
        padding: 100px 115px;
        @media (max-width: 672px) {
            padding: 30px 0px;
        }
    }
}

.form-order {
    &__title {
        font-weight: 600;
        font-size: calc(17px + (32 - 17) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(25px + (48 - 25) * ((100vw - 320px) / (1920 - 320)));
        text-align: center;
        color: $txt-white;
        max-width: 790px;
        margin: 0 auto;
        margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @media (max-width: 965px) {
            flex-direction: column;
            align-items: center;
        }

    }
    &__left {
        flex: 0 1 410px;
        margin-right: 20px;
        @media (max-width: 965px) {
            flex: 0 1 auto;
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
    &__prices {
        text-align: center;
        font-size: 18px;
        line-height: 27px;
        color: $txt-white;
        @media (max-width: 1136px) {
            font-size: 13px;
            line-height: 19px;
        }
        @media (max-width: 965px) {
            margin-bottom: 10px;
        }
    }
    &__timer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $bg-violet;
        border-radius: 10px;
        padding: 33.5px 50px;
        @media (max-width: 1201px) {
            padding: 32px 40px;
        }
        @media (max-width: 1156px) {
            padding: 21px 40px;
        }
        @media (max-width: 445px) {
            flex-direction: column;
            text-align: center;
        }
    }
    &__text-promo {
        max-width: 165px;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: $txt-white;
        @media (max-width: 479px) {
            font-size: 15px;
            line-height: 22px;
        }
    }
    &__right {
        flex: 0 1 520px;
        @media (max-width: 965px) {
            max-width: 410px;
            flex: 0 1 auto;
            
        }
    }
    &__text {
        font-size: 18px;
        line-height: 27px;
        color: $txt-white;
        margin-bottom: 7px;
        @media (max-width: 1136px) {
            font-size: 13px;
            line-height: 19px;
        }
        @media (max-width: 965px) {
            text-align: center;
        }
    }
    &__input {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        background: #280F36;
        background:
            linear-gradient(#280F36, #280F36) padding-box,
            linear-gradient(135deg, #642B73 0%, #C6426E 100%) border-box;
        border: 3px solid transparent;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 13px 20px;
        &::placeholder {
            color: rgba(245, 245, 245, 0.6);
        }
    }
    &__btn {
        transition: all 0.4s ease-in-out;
        background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
        border-radius: 10px;
        &:hover {
            
        }
    }
}

.order__pointer {
    @media (max-width: 1920px) {
        width: calc(235px + (390 - 235) * ((100vw - 320px) / (1920 - 320)));
    }
    position: absolute;
    z-index: 10;
    top: -120px;
    right: 0px;
    &-wrapper {
        position: relative;
    }
    &-text {
        font-family: "Pacifico", sans-serif;
        width: 180px;
        position: absolute;
        font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(30px + (42 - 30) * ((100vw - 320px) / (1920 - 320)));
        color: $txt-violet2;
        transform: rotate(13.08deg);
    }
    &-img {
        position: absolute;
        top: 0;
        width: calc(130px + (309 - 130) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width: 1440px) {
            top: 20px;
            right: 60px;
        }
    }
}