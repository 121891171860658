.btn {
    display: block;
    max-width: 520px;
    width: 100%;
    max-height: 59px;
    background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 10px;
    font-weight: 700;
    font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(20px + (27 - 20) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    color: #F5F5F5;
    transition: 0.3s background;
    &:hover {
        background: linear-gradient(135deg, #C86B98 0%, #C6426E 100%);
    }
}