.offer {
    &__inner {
        padding-top: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(70px + (220 - 70) * ((100vw - 320px) / (1920 - 320)));
    }
    &__text-detail {
        margin-bottom: 10px;
    }
    &__title {
        margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
        display: block;
        position: relative;
        max-width: calc(280px + (650 - 280) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width: 1600px) {
            max-width: calc(280px + (750 - 280) * ((100vw - 320px) / (1920 - 320)));
        }
        @media (max-width: 800px) {
            max-width: calc(280px + (900 - 280) * ((100vw - 320px) / (1920 - 320)));
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            background: url(../img/offer-stars.png) no-repeat;
            background-size: 48px;
            @media (max-width: 800px) {
                top: -50px;
            }
        }
    }
    &__list {
        display: grid;
        grid-template: auto / repeat(auto-fill,minmax(280px, 1fr));
        gap: 75px;
        @media (max-width: 479px) {
            gap: 25px;
        }
    }
    &__item {
        text-align: center;
        @media (max-width: 479px) {
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    &__img {
        margin-bottom: 20px;
        @media (max-width: 479px) {
            margin-bottom: 0;
        }
    }
    &__item-descr {
        font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
    }
}