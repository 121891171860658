.life {
    &__inner {
        padding-top: 130px;
        padding-bottom: 65px;
        background: 
            url(../img/life-arrow-top.png) top 90px center no-repeat,
            url(../img/life-arrow-bot.png) bottom center no-repeat;
        background-size: 600px, 600px;
        @media (max-width: 1920px) {
            background-size: calc(250px + (600 - 250) * ((100vw - 320px) / (2400 - 320))), calc(250px + (600 - 250) * ((100vw - 320px) / (2400 - 320)));
        }
    }
    &__wrapper {
        position: relative;
        min-height: calc(700px + (585 - 700) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__images-top {
        display: flex;
        justify-content: space-between;
    }
    &__img {
        width: 300px;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
        @media (max-width: 1920px) {
            width: calc(130px + (300 - 130) * ((100vw - 320px) / (1920 - 320)));
            height: calc(130px + (200 - 130) * ((100vw - 320px) / (1920 - 320)));
        }
    }
    &__img-1 {

    }
    &__img-2 {

    }
    &__descr {
        max-width: 530px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    }
    &__images-bot {
        display: flex;
        justify-content: space-between;
    }
    &__img-3 {

    }
    &__img-4 {

    }
}