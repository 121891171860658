.timer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 140px;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &__num {
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      color: #F5F5F5;
      text-align: center;
      &--dots {
        position: relative;
        &:after {
          content: ":";
          position: absolute;
          right: -24px;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;
          color: #F5F5F5;
        }
      }
    }
    &__text {
      font-size: 13px;
      line-height: 17px;
      color: #F5F5F5;
      text-align: center;
    }
  }
  @media (max-width: 740px) {
    .timer {
      max-width: 98px;
      &__item {
        max-width: 40px;
        margin-right: 17px;
      }
      &__num {
        padding: 7px 5px;
        width: 40px;
        &--dots {
          &:after {
            right: -12px;
          }
        }
      }
    }
  }