@charset "UTF-8";
/* colors */
/* @font-face {
  font-family: 'Bebas Neue Pro';
  font-display: swap;
  src: url('../fonts/BebasNeuePro-ExpandedExtraBold-800.woff2') format('woff2'),
      url('../fonts/BebasNeuePro-ExpandedExtraBold-800.woff') format('woff');
  font-weight: 800;
  font-style: normal;
} */
/* Стандартный transition для hover-анимаций */
/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
html, body {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #815F86; }

a, a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input; }

.fade-animation {
  -webkit-animation: fade-animation;
  animation: fade-animation 2s linear; }

@-webkit-keyframes fade-animation {
  from {
    opacity: 0.1; }
  to {
    opacity: 1; } }

.active {
  display: block; }

/* container */
.container {
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 20px; }

.text-detail {
  font-weight: 600;
  font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));
  text-transform: uppercase;
  color: #FE9C7F; }

.title {
  font-family: "Ubuntu", sans-serif;
  font-size: calc(24px + (52 - 24) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(31px + (68 - 31) * ((100vw - 320px) / (1920 - 320)));
  color: #280F36; }

.btn {
  display: block;
  max-width: 520px;
  width: 100%;
  max-height: 59px;
  background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 10px;
  font-weight: 700;
  font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(20px + (27 - 20) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
  color: #F5F5F5;
  transition: 0.3s background; }
  .btn:hover {
    background: linear-gradient(135deg, #C86B98 0%, #C6426E 100%); }

.separator {
  height: calc(35px + (90 - 35) * ((100vw - 320px) / (1920 - 320)));
  width: 100%;
  z-index: 4;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  /* @media (max-width: 767px) {
      height: 40px;
    }
    @media (max-width: 479px) {
      height: 35px;
    } */ }

.separator-top {
  top: 0; }

.separator-bottom {
  bottom: 0; }

.separator svg {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%; }

.price__new {
  display: inline-block;
  font-weight: bold;
  font-size: 42px;
  line-height: 63px;
  color: #FE9C7F; }
  @media (max-width: 1126px) {
    .price__new {
      font-size: 30px;
      line-height: 45px; } }
  .price__new span {
    margin-right: 10px !important;
    margin-left: 10px !important; }

.price__text {
  display: inline-block;
  margin: 0;
  margin-left: 10px; }

.price__old {
  display: inline-block;
  text-decoration: line-through; }

.timer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 140px; }
  .timer__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .timer__num {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    color: #F5F5F5;
    text-align: center; }
    .timer__num--dots {
      position: relative; }
      .timer__num--dots:after {
        content: ":";
        position: absolute;
        right: -24px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        color: #F5F5F5; }
  .timer__text {
    font-size: 13px;
    line-height: 17px;
    color: #F5F5F5;
    text-align: center; }

@media (max-width: 740px) {
  .timer {
    max-width: 98px; }
    .timer__item {
      max-width: 40px;
      margin-right: 17px; }
    .timer__num {
      padding: 7px 5px;
      width: 40px; }
      .timer__num--dots:after {
        right: -12px; } }

.form__mail {
  display: none;
  padding: 16px 21px 29px 16px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(165, 165, 165, 0.21);
  border-radius: 4px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 567px) {
    .form__mail {
      padding: 16px 24px 24px; } }

.form__title {
  font-family: 'Gotham', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 8px; }
  @media only screen and (max-width: 1023px) {
    .form__title {
      font-size: 16px; } }
  @media only screen and (max-width: 767px) {
    .form__title {
      font-size: 12px; } }

.form__input-container {
  position: relative; }

.form__input {
  background: #E5E5E5;
  border: 1px solid #CBCBCB;
  border-radius: 4px;
  width: 100%;
  padding: 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #CBCBCB;
  text-indent: 11px; }
  .form__input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #CBCBCB; }

.basket__content {
  width: 100%; }
  @media only screen and (max-width: 567px) {
    .basket__content {
      max-width: 100%; } }

.basket__button {
  width: 100%;
  border: none;
  outline: none;
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 10; }
  .basket__button:hover {
    transform: scale(1.05);
    background: linear-gradient(135deg, #C86B98 0%, #C6426E 100%); }
  .basket__button:disabled {
    cursor: not-allowed;
    background: grey; }

.basket__check, .basket__check2 {
  position: relative;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
  z-index: 10;
  /* margin-bottom: 18px; */
  display: none; }

.basket__check-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.basket__check-box {
  user-select: none; }

.basket__check-input + label::before {
  content: '';
  display: inline-block;
  margin-right: 3px;
  width: 16px;
  height: 16px;
  border: 1px solid #535353;
  border-radius: 4px;
  cursor: pointer; }

.basket__check-input:checked + label::before {
  background-image: url("../img/checkPay.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px; }

.basket__check-text {
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  color: rgba(245, 245, 245, 0.6); }
  .basket__check-text--data {
    text-decoration: underline; }
  .basket__check-text--top {
    font-size: 10px; }
  .basket__check-text--data-top {
    text-decoration: underline;
    font-size: 12px;
    color: blue; }

.new-price,
.old-price {
  white-space: nowrap; }

.old-price {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #535353;
  position: relative; }
  .old-price:before {
    content: '';
    width: 119%;
    height: 1px;
    background-color: #535353;
    position: absolute;
    transform: rotate(-15deg) translate(-8px, 9px); }
  @media only screen and (max-width: 567px) {
    .old-price {
      font-size: 12px;
      margin-bottom: 8px; }
      .old-price:before {
        transform: rotate(-15deg) translate(-5px, 7px); } }

.new-price {
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  color: #FF001F; }
  .new-price_sm {
    font-size: 14px; }

.footer {
  background-color: transparent;
  padding: 40px 0 21px;
  display: flex;
  align-items: flex-end;
  flex-grow: 4; }
  .footer__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px; }
  .footer__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 270px; }
  .footer__info {
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 114%;
    color: #000000;
    margin-bottom: 8px; }
    .footer__info--link {
      text-decoration: none; }
    .footer__info--grey {
      color: #595959; }
    .footer__info--legal {
      margin-bottom: 0;
      line-height: 170%; }
  .footer__pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 14px;
    width: 100%;
    max-width: 280px; }

@media (max-width: 800px) {
  .footer__inner {
    flex-wrap: wrap; }
  .footer__item--middle {
    order: 2; }
  .footer__info--mb {
    color: #595959; } }

@media (max-width: 740px) {
  .footer {
    padding: 16px 0 0; }
  .main-link {
    padding: 0 16px;
    margin: 16px 0; } }

.header {
  position: fixed;
  background: rgba(40, 15, 54, 0.8);
  width: 100%;
  top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 50; }
  .header__inner {
    display: flex;
    align-items: center; }
    @media (max-width: 479px) {
      .header__inner {
        justify-content: flex-end; } }
  .header__logo {
    max-width: 108px;
    margin-right: auto; }
    .header__logo img {
      width: 100%; }
  .header__enter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 43px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #F5F5F5;
    background: #280F36;
    border-radius: 10px;
    transition: 0.3s background; }
    .header__enter::before {
      content: "";
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
      border-radius: 12px;
      z-index: -1; }
    .header__enter:hover {
      background: linear-gradient(135deg, #642B73 0%, #C6426E 100%); }

.main {
  background: url(../img/main-bg-desk.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative; }
  @media (max-width: 479px) {
    .main {
      background: url(../img/main-bg-mobile.jpg) no-repeat;
      background-position: bottom center;
      background-size: cover; } }
  .main__inner {
    padding-top: 210px;
    padding-bottom: 270px; }
    @media (max-width: 767px) {
      .main__inner {
        padding-top: 165px;
        padding-bottom: 165px; } }
    @media (max-width: 320px) {
      .main__inner {
        padding-top: 225px;
        padding-bottom: 90px; } }
  .main__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .main__wrapper {
        justify-content: center; } }
  .main__block-text {
    max-width: 500px; }
  .main__title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: calc(40px + (86 - 40) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(48px + (103 - 48) * ((100vw - 320px) / (1920 - 320)));
    color: #F5F5F5;
    margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))); }
    @media (max-width: 767px) {
      .main__title {
        text-align: center;
        margin-bottom: 20px; } }
    @media (max-width: 360px) {
      .main__title {
        max-width: 250px;
        margin: 0 auto;
        margin-bottom: 20px; } }
  .main__subtitle {
    font-size: 20px;
    line-height: 30px;
    color: #F5F5F5; }
    @media (max-width: 949px) {
      .main__subtitle {
        margin-bottom: 80px; } }
    @media (max-width: 767px) {
      .main__subtitle {
        text-align: center; } }
  .main__block-form {
    flex: 0 1 410px; }
  .main__form-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #F5F5F5;
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      .main__form-title {
        display: block;
        text-align: center; } }
  .main__btn {
    max-width: 410px;
    width: 100%; }
    @media (max-width: 767px) {
      .main__btn {
        margin: 0 auto; } }

.body__bg2 {
  position: absolute;
  z-index: 500;
  left: 0;
  top: 2450px; }
  @media (max-width: 767px) {
    .body__bg2 {
      display: none; } }

.body__bg3 {
  position: absolute;
  z-index: 500;
  left: 0;
  top: 5350px; }
  @media (max-width: 767px) {
    .body__bg3 {
      display: none; } }

.body__bg4 {
  position: absolute;
  z-index: 500;
  right: 0;
  top: 7750px; }
  @media (max-width: 767px) {
    .body__bg4 {
      display: none; } }

.change {
  position: relative; }
  .change__inner {
    padding-top: 80px;
    padding-bottom: 65px; }
    @media (max-width: 479px) {
      .change__inner {
        padding-top: 70px;
        padding-bottom: 40px; } }
  .change__text-detail {
    margin-bottom: 10px; }
  .change__title {
    margin-bottom: 60px; }
    @media (max-width: 479px) {
      .change__title {
        margin-bottom: 30px; } }
  .change__list {
    display: grid;
    grid-template: auto/repeat(auto-fill, minmax(280px, 1fr));
    gap: 75px; }
    @media (max-width: 479px) {
      .change__list {
        gap: 20px; } }
  .change__item-title {
    font-weight: 600;
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    color: #280F36;
    padding-left: 35px;
    position: relative;
    margin-bottom: 20px; }
    @media (max-width: 479px) {
      .change__item-title {
        margin-bottom: 10px; } }
    .change__item-title::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background: url(../img/change-star.png) no-repeat; }
  .change__item-descr {
    padding-left: 35px; }
  .change__bg {
    position: absolute;
    top: -100px;
    right: 0; }
    @media (max-width: 767px) {
      .change__bg {
        display: none; } }

.offer__inner {
  padding-top: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(70px + (220 - 70) * ((100vw - 320px) / (1920 - 320))); }

.offer__text-detail {
  margin-bottom: 10px; }

.offer__title {
  margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  display: block;
  position: relative;
  max-width: calc(280px + (650 - 280) * ((100vw - 320px) / (1920 - 320))); }
  @media (max-width: 1600px) {
    .offer__title {
      max-width: calc(280px + (750 - 280) * ((100vw - 320px) / (1920 - 320))); } }
  @media (max-width: 800px) {
    .offer__title {
      max-width: calc(280px + (900 - 280) * ((100vw - 320px) / (1920 - 320))); } }
  .offer__title::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background: url(../img/offer-stars.png) no-repeat;
    background-size: 48px; }
    @media (max-width: 800px) {
      .offer__title::after {
        top: -50px; } }

.offer__list {
  display: grid;
  grid-template: auto/repeat(auto-fill, minmax(280px, 1fr));
  gap: 75px; }
  @media (max-width: 479px) {
    .offer__list {
      gap: 25px; } }

.offer__item {
  text-align: center; }
  @media (max-width: 479px) {
    .offer__item {
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center; } }

.offer__img {
  margin-bottom: 20px; }
  @media (max-width: 479px) {
    .offer__img {
      margin-bottom: 0; } }

.offer__item-descr {
  font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320))); }

.program {
  background: #f9edf2;
  position: relative; }
  .program__inner {
    padding-top: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(65px + (130 - 65) * ((100vw - 320px) / (1920 - 320))); }
  .program__text-detail {
    text-align: center;
    margin-bottom: 10px; }
  .program__title {
    text-align: center;
    margin-bottom: calc(30px + (80 - 30) * ((100vw - 320px) / (1920 - 320))); }
  .program__list {
    display: grid;
    justify-items: center;
    grid-template: auto/repeat(auto-fill, minmax(280px, 1fr));
    gap: 75px;
    margin-bottom: 60px; }
    @media (max-width: 674px) {
      .program__list {
        gap: 30px; } }
  .program__img {
    margin-bottom: 20px; }
    .program__img img {
      width: 100%;
      border-radius: 40px 0px; }
      @media (max-width: 360px) {
        .program__img img {
          border-radius: 30px 0px; } }
  .program__text {
    background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px; }
  .program__item-title {
    font-weight: 600;
    font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(25px + (36 - 25) * ((100vw - 320px) / (1920 - 320)));
    color: #280F36;
    margin-bottom: 10px; }
  .program__descr {
    max-width: 380px;
    font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(19px + (27 - 19) * ((100vw - 320px) / (1920 - 320))); }
    @media (max-width: 674px) {
      .program__descr {
        max-width: 280px; } }
  .program__btn {
    position: relative;
    border: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(280px + (410 - 280) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (59 - 50) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(20px + (27 - 20) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    color: #280F36;
    background: linear-gradient(#F9EDF2, #F9EDF2) padding-box, linear-gradient(135deg, #642B73 0%, #C6426E 100%) border-box;
    border-radius: 10px;
    border: 3px solid transparent;
    transition: all 0.3s; }
    .program__btn:hover {
      background: linear-gradient(135deg, #642B73 0%, #C6426E 100%) border-box;
      color: #F5F5F5; }

.separator__program-top {
  top: calc(-35px + (-90 - -35) * ((100vw - 320px) / (1920 - 320))); }
  .separator__program-top path {
    fill: #F9EDF2; }

.separator__program-bottom {
  bottom: calc(-35px + (-90 - -35) * ((100vw - 320px) / (1920 - 320))); }
  .separator__program-bottom path {
    fill: #F9EDF2; }

.get {
  position: relative;
  overflow: hidden; }
  .get__inner {
    max-width: 1520px;
    padding-top: calc(85px + (215 - 85) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320))); }
  .get__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url(../img/get-bg.jpg) no-repeat;
    background-size: cover;
    border-radius: 10px;
    padding: 100px 115px;
    padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(20px + (115 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(20px + (115 - 20) * ((100vw - 320px) / (1920 - 320))); }
    .get__wrapper::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 220px;
      height: 173px;
      top: -80px;
      right: -80px;
      background: url(../img/get-stars.png) no-repeat; }
    @media (max-width: 767px) {
      .get__wrapper {
        flex-wrap: wrap;
        text-align: center; } }
  .get__block-text {
    max-width: 650px;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .get__block-text {
        margin-right: 0; } }
  .get__block-title {
    font-weight: 600;
    font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(25px + (36 - 25) * ((100vw - 320px) / (1920 - 320)));
    color: #F5F5F5;
    margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))); }
  .get__block-subtitle {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    color: #F5F5F5; }
    @media (max-width: 767px) {
      .get__block-subtitle {
        margin-bottom: 40px; } }
  .get__form {
    width: 100%;
    max-width: 520px; }
    @media (max-width: 767px) {
      .get__form {
        margin: 0 auto; } }
  .get__form-title {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    color: #F5F5F5; }
  .get__form-btn {
    background: linear-gradient(135deg, #C86B98 0%, #C6426E 100%);
    transition: background 0.3s; }
    .get__form-btn:hover {
      background: linear-gradient(135deg, #642B73 0%, #C6426E 100%); }

.know {
  overflow: hidden; }
  .know__inner {
    padding-top: calc(30px + (65 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(170px + (150 - 170) * ((100vw - 320px) / (1920 - 320))); }
  .know__text-detail {
    margin-bottom: 10px; }
  .know__title {
    margin-bottom: 60px;
    z-index: 10;
    position: relative; }
    @media (max-width: 479px) {
      .know__title {
        margin-bottom: 30px; } }
  .know__descr {
    max-width: 630px;
    margin-bottom: calc(150px + (190 - 150) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320))); }
  .know__list {
    display: flex;
    flex-direction: column; }
  .know__item {
    position: relative;
    z-index: 5; }
    .know__item:nth-child(1) {
      margin-bottom: 180px;
      margin-left: auto;
      margin-right: 200px; }
      @media (max-width: 667px) {
        .know__item:nth-child(1) {
          margin: 0 auto 170px; } }
      @media (max-width: 360px) {
        .know__item:nth-child(1) {
          margin: 0 auto 130px; } }
    .know__item:nth-child(2) {
      margin-bottom: 120px;
      margin-left: 200px; }
      @media (max-width: 767px) {
        .know__item:nth-child(2) {
          margin-bottom: 180px; } }
      @media (max-width: 667px) {
        .know__item:nth-child(2) {
          margin: 0 auto 170px; } }
      @media (max-width: 360px) {
        .know__item:nth-child(2) {
          margin: 0 auto 130px; } }
    .know__item:nth-child(3) {
      margin-bottom: 180px;
      margin-left: auto;
      margin-right: 200px; }
      @media (max-width: 667px) {
        .know__item:nth-child(3) {
          margin: 0 auto 170px; } }
      @media (max-width: 360px) {
        .know__item:nth-child(3) {
          margin: 0 auto 130px; } }
    .know__item:nth-child(4) {
      margin-left: 200px; }
      @media (max-width: 667px) {
        .know__item:nth-child(4) {
          margin: 0 auto; } }
  .know__item-wrapper {
    display: flex;
    max-width: 545px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10; }
    @media (max-width: 667px) {
      .know__item-wrapper {
        flex-direction: column; } }
  .know__item-block {
    order: 1;
    background: #FFFFFF;
    width: 385px;
    height: 130px;
    padding: 10px 10px 20px;
    border-radius: 10px; }
    @media (max-width: 667px) {
      .know__item-block {
        width: 280px;
        order: 5;
        height: 100px;
        padding: 10px 20px; } }
    .know__item-block--1, .know__item-block--3 {
      text-align: right;
      margin-right: 10px; }
      @media (max-width: 667px) {
        .know__item-block--1, .know__item-block--3 {
          text-align: left;
          margin-right: 0; } }
    .know__item-block--2, .know__item-block--4 {
      text-align: left; }
  .know__item-title {
    font-weight: 600;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    color: #280F36;
    margin-bottom: 10px; }
  .know__item-text {
    font-size: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320))); }
  @media (max-width: 667px) {
    .know__item-img {
      order: 1;
      margin-bottom: 10px;
      width: 100px;
      height: 100px; } }
  .know__item-img--1, .know__item-img--3 {
    order: 2;
    border-radius: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))) 0px; }
  .know__item-img--2, .know__item-img--4 {
    border-radius: 0px calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))); }
  .know__item-bg {
    position: absolute;
    z-index: -1;
    width: calc(280px + (450 - 280) * ((100vw - 320px) / (1920 - 320)));
    height: calc(280px + (450 - 280) * ((100vw - 320px) / (1920 - 320)));
    bottom: -55px; }
    .know__item-bg--1, .know__item-bg--3 {
      right: -200px; }
      @media (max-width: 767px) {
        .know__item-bg--1, .know__item-bg--3 {
          right: -100px; } }
      @media (max-width: 667px) {
        .know__item-bg--1, .know__item-bg--3 {
          left: 50%;
          transform: translateX(-50%);
          bottom: 45px; } }
    .know__item-bg--2, .know__item-bg--4 {
      left: -200px; }
      @media (max-width: 767px) {
        .know__item-bg--2, .know__item-bg--4 {
          left: -100px; } }
      @media (max-width: 667px) {
        .know__item-bg--2, .know__item-bg--4 {
          left: 50%;
          transform: translateX(-50%);
          bottom: 45px; } }

.order {
  max-width: 1520px;
  margin: 0 auto;
  background: url(../img/order-bg.jpg) no-repeat;
  background-size: cover; }
  @media (max-width: 360px) {
    .order {
      background: url(../img/order-bg-mobile.jpg) no-repeat;
      background-size: cover; } }
  .order__inner {
    position: relative; }
  .order__form {
    padding: 100px 115px; }
    @media (max-width: 672px) {
      .order__form {
        padding: 30px 0px; } }

.form-order__title {
  font-weight: 600;
  font-size: calc(17px + (32 - 17) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(25px + (48 - 25) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
  color: #F5F5F5;
  max-width: 790px;
  margin: 0 auto;
  margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))); }

.form-order__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline; }
  @media (max-width: 965px) {
    .form-order__wrapper {
      flex-direction: column;
      align-items: center; } }

.form-order__left {
  flex: 0 1 410px;
  margin-right: 20px; }
  @media (max-width: 965px) {
    .form-order__left {
      flex: 0 1 auto;
      margin-right: 0;
      margin-bottom: 30px; } }

.form-order__prices {
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  color: #F5F5F5; }
  @media (max-width: 1136px) {
    .form-order__prices {
      font-size: 13px;
      line-height: 19px; } }
  @media (max-width: 965px) {
    .form-order__prices {
      margin-bottom: 10px; } }

.form-order__timer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #632B6C;
  border-radius: 10px;
  padding: 33.5px 50px; }
  @media (max-width: 1201px) {
    .form-order__timer {
      padding: 32px 40px; } }
  @media (max-width: 1156px) {
    .form-order__timer {
      padding: 21px 40px; } }
  @media (max-width: 445px) {
    .form-order__timer {
      flex-direction: column;
      text-align: center; } }

.form-order__text-promo {
  max-width: 165px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #F5F5F5; }
  @media (max-width: 479px) {
    .form-order__text-promo {
      font-size: 15px;
      line-height: 22px; } }

.form-order__right {
  flex: 0 1 520px; }
  @media (max-width: 965px) {
    .form-order__right {
      max-width: 410px;
      flex: 0 1 auto; } }

.form-order__text {
  font-size: 18px;
  line-height: 27px;
  color: #F5F5F5;
  margin-bottom: 7px; }
  @media (max-width: 1136px) {
    .form-order__text {
      font-size: 13px;
      line-height: 19px; } }
  @media (max-width: 965px) {
    .form-order__text {
      text-align: center; } }

.form-order__input {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  background: #280F36;
  background: linear-gradient(#280F36, #280F36) padding-box, linear-gradient(135deg, #642B73 0%, #C6426E 100%) border-box;
  border: 3px solid transparent;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 13px 20px; }
  .form-order__input::placeholder {
    color: rgba(245, 245, 245, 0.6); }

.form-order__btn {
  transition: all 0.4s ease-in-out;
  background: linear-gradient(135deg, #642B73 0%, #C6426E 100%);
  border-radius: 10px; }

.order__pointer {
  position: absolute;
  z-index: 10;
  top: -120px;
  right: 0px; }
  @media (max-width: 1920px) {
    .order__pointer {
      width: calc(235px + (390 - 235) * ((100vw - 320px) / (1920 - 320))); } }
  .order__pointer-wrapper {
    position: relative; }
  .order__pointer-text {
    font-family: "Pacifico", sans-serif;
    width: 180px;
    position: absolute;
    font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(30px + (42 - 30) * ((100vw - 320px) / (1920 - 320)));
    color: #632B6C;
    transform: rotate(13.08deg); }
  .order__pointer-img {
    position: absolute;
    top: 0;
    width: calc(130px + (309 - 130) * ((100vw - 320px) / (1920 - 320))); }
    @media (max-width: 1440px) {
      .order__pointer-img {
        top: 20px;
        right: 60px; } }

.life__inner {
  padding-top: 130px;
  padding-bottom: 65px;
  background: url(../img/life-arrow-top.png) top 90px center no-repeat, url(../img/life-arrow-bot.png) bottom center no-repeat;
  background-size: 600px, 600px; }
  @media (max-width: 1920px) {
    .life__inner {
      background-size: calc(250px + (600 - 250) * ((100vw - 320px) / (2400 - 320))), calc(250px + (600 - 250) * ((100vw - 320px) / (2400 - 320))); } }

.life__wrapper {
  position: relative;
  min-height: calc(700px + (585 - 700) * ((100vw - 320px) / (1920 - 320)));
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.life__images-top {
  display: flex;
  justify-content: space-between; }

.life__img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px; }
  @media (max-width: 1920px) {
    .life__img {
      width: calc(130px + (300 - 130) * ((100vw - 320px) / (1920 - 320)));
      height: calc(130px + (200 - 130) * ((100vw - 320px) / (1920 - 320))); } }

.life__descr {
  max-width: 530px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320))); }

.life__images-bot {
  display: flex;
  justify-content: space-between; }

.faq__inner {
  padding-top: calc(70px + (130 - 70) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320))); }

.faq__text-detail {
  margin-bottom: 10px; }

.faq__title {
  margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320))); }

.faq__wrapper {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1050px) {
    .faq__wrapper {
      flex-direction: column; } }

.faq__list {
  flex: 0 1 600px; }
  @media (max-width: 1050px) {
    .faq__list {
      flex: 0 1 auto; } }
  .faq__list--left {
    margin-right: 20px; }
    @media (max-width: 1050px) {
      .faq__list--left {
        margin-right: 0; } }

.faq__item {
  border-bottom: 1px solid #815F86; }
  .faq__item.active .faq__icon-down {
    display: none; }
  .faq__item.active .faq__icon-up {
    display: block;
    transform: scale(1); }

.faq__question {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
  color: #280F36; }

.faq__question-text {
  font-weight: 600;
  margin-right: auto;
  font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320))); }
  @media (max-width: 479px) {
    .faq__question-text {
      max-width: calc(240px + (420 - 240) * ((100vw - 320px) / (479 - 320))); } }

.faq__icon-up {
  display: none; }

.faq__answer {
  display: none;
  padding-bottom: calc(17px + (40 - 17) * ((100vw - 320px) / (1920 - 320)));
  font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
  line-height: calc(19px + (27 - 19) * ((100vw - 320px) / (1920 - 320))); }

.reviews {
  position: relative; }
  .reviews__inner {
    padding-top: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320))); }
  .reviews__list {
    display: grid;
    gap: calc(30px + (90 - 30) * ((100vw - 320px) / (1920 - 320)));
    grid-template-columns: repeat(2, 1fr); }
    @media (max-width: 767px) {
      .reviews__list {
        grid-template-columns: 100%; } }
  .reviews__item:not(:first-child) {
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, rgba(200, 107, 152, 0.1) 0%, rgba(198, 66, 110, 0.1) 100%);
    border-radius: 20px;
    padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))) calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320))); }
  .reviews__text-detail {
    margin-bottom: 10px; }
  .reviews__title {
    margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320))); }
  .reviews__text {
    margin-bottom: auto; }
  .reviews__item-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))); }
    @media (max-width: 767px) {
      .reviews__item-wrapper {
        flex-direction: column;
        justify-content: center;
        text-align: center; } }
  .reviews__item-title {
    font-weight: 600;
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    color: #280F36; }
    @media (max-width: 767px) {
      .reviews__item-title {
        order: 2; } }
  .reviews__rating {
    width: 140px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .reviews__rating {
        order: 1;
        margin: 0 auto;
        margin-bottom: 13px; } }
  .reviews__date {
    font-size: 15px;
    line-height: 22px;
    color: #280F36;
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))); }

.separator__program-bottom--color path {
  fill: #fff; }

.footer {
  background: url(../img/footer-bg.jpg) no-repeat;
  background-size: cover;
  padding: 0;
  padding-top: calc(55px + (160 - 55) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(55px + (100 - 55) * ((100vw - 320px) / (1920 - 320))); }
  @media (max-width: 360px) {
    .footer {
      background: url(../img/footer-bg-mobile.jpg) no-repeat;
      background-size: cover; } }
  .footer__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .footer__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 270px; }
  .footer__info {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 19px;
    color: #F5F5F5;
    margin-bottom: 8px; }
    .footer__info--link {
      text-decoration: none; }
    .footer__info--legal {
      margin-bottom: 0;
      line-height: 170%; }
  .footer__pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 14px;
    width: 100%;
    max-width: 155px; }

@media (max-width: 800px) {
  .footer__inner {
    flex-wrap: wrap; }
  .footer__item--middle {
    order: 2; } }

@media (max-width: 400px) {
  .footer__item--middle {
    max-width: 100%; }
  .footer__pay {
    max-width: 100%; } }
