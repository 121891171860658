.footer {
  background: url(../img/footer-bg.jpg) no-repeat;
  background-size: cover;
  padding: 0;
  padding-top: calc(55px + (160 - 55) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(55px + (100 - 55) * ((100vw - 320px) / (1920 - 320)));
  @media (max-width: 360px) {
    background: url(../img/footer-bg-mobile.jpg) no-repeat;
    background-size: cover;
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 270px;
  }
  &__info {
    font-weight: 400;
    font-size: rem(14);
    line-height: 19px;
    color: #F5F5F5;
    margin-bottom: 8px;
    &--link {
      text-decoration: none;
    }
    &--legal {
      margin-bottom: 0;
      line-height: 170%;
    }
  }
  &__pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 14px;
    width: 100%;
    max-width: 155px;
  }
}
@media (max-width: 800px) {
  .footer {
    &__inner {
      flex-wrap: wrap;
    }
    &__item{
      &--middle {
        order: 2;
      }
    }

  }
}
@media (max-width: 400px) {
  .footer {
    &__inner {
      
    }
    &__item{
      &--middle {
        max-width: 100%;
      }
    }
    &__pay {
      max-width: 100%;
    }
  }
}